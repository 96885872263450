import React from "react"
import { graphql } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"
import Repos, { ReposTabs } from "../components/Repos"
import Container from 'react-bootstrap/Container'
import Footer from '../components/footer'

export const query = graphql`  
  query RepoGroupQuery($group: String!) {
    allStrapiRepos(sort: {fields: repo_name, order: ASC}, filter: {group_name: {eq: $group}}) {
      nodes {
        releases {
          tag_name
          create_time
          id
        }
        group_name
        strapiId
        repo_name
        description
        id
      }
    }
  }
`

export default ({ data, pageContext }) => {
  let tab = 'v4', arr = ['v2', 'v3', 'v4'];
  if(typeof window !== "undefined") {
    const v = window.location.href.substring(window.location.href.lastIndexOf('/')-2, window.location.href.lastIndexOf('/'))
    tab =  arr.indexOf(v)>-1 ? v : 'v4'
  }
  const [ active, setActive ] = React.useState(tab)
 
  return (
    <div>
      <div className="home-bg" style={{ background: `url('')`, height: '7.5rem' }}>
        <Layout><SEO title="物联网平台_数采平台_工业物联网 ——低代码搭建IOT平台" /></Layout>
      </div>
      <Container>
        {/* <BreadCrumb title="模块列表" href="/repos/front" style={{ margin: '2rem 0' }} flag={true}></BreadCrumb> */}
        <div className="repos-title-box">
          <span className="title-line"></span>
          <span className="title-repos">模块列表</span>
        </div>
        <ReposTabs activeGroup={pageContext.group} active={active} setActive={setActive}/>
        <Repos repos={data.allStrapiRepos.nodes} showGroup={false} active={active}/>
      </Container>
      <Footer style={{ marginTop: '7.5rem' }}></Footer>
    </div>
  )
}

